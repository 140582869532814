import React from 'react';
import Layout from '../../../components/layout/Layoutexport-en';

import { StaticImage } from "gatsby-plugin-image"





const seo={
  title: "About Us",
  desc:  "Descubra nuestra historia",
  image: "productos.jpg",
  pathname: "/export/en/about-us/",
  alternateEn: "/export/en/about-us/",
  alternateEs: "/export/es/acerca-de/",
  alternateEsec: "/quienes-somos/",
  alternateDefault: "/export/es/acerca-de/"

}

const siteVerOpts = [

  {
    label: 'International',
    value: '',

  },


  {
    label: 'Ecuador',
    value: '/quienes-somos/',

  },


];

const langOpts = [

  {
    label: 'EN',
    value: '/export/en/about-us/',

  },


  {
    label: 'ES',
    value: '/export/es/acerca-de/',

  },


];

const AboutUs = ({location}) => (




  <Layout location={location} crumbLabel="About us"
  alternates alternateEn={seo.alternateEn} alternateEs={seo.alternateEs} alternateEsec={seo.alternateEsec} alternateDefault={seo.alternateDefault}
          siteVerOpts={siteVerOpts} langOpts={langOpts}
            title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname} pageLang={seo.pageLang}>


    <StaticImage src="../../../images/equipo-unilimpio.jpg"
                className="object-contain"
                alt="Acerca de Unilimpio: en la imagen todos los colaboradores de Unilimpio oficina Matriz."
                placeholder="blurred"
                layout="fullWidth"
                 />

<section id="presentacion" className="bg-white flex justify-center">
      <div className="max-w-7xl mx-10 py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="text-5xl font-semibold text-primary-lighter ">We are Health and Wellness</h1>
           <p className="mt-4 text-2xl text-primary-default mb-6 leading-relaxed ">We are a proudly Ecuadorian company that for more than 3 decades has been providing health and wellness to people through cleaning and hygiene.</p>
        </div>
      </div>
    </section>





    <section id="historia" className="bg-primary-lighter flex justify-center">
     <div className="max-w-7xl mx-10 py-20 px-4 sm:px-6 lg:py-24 lg:px-8">
      <div className="max-w-3xl mx-auto text-center">
       <h2 className="text-5xl font-semibold text-white text-center pb-4 ">OUR HISTORY</h2>
        <p className="mt-4 text-2xl text-slate-100 mb-20 font-normal text-left">Unilimpio S.A. is an Ecuadorian company founded in 1986. We are dedicated to providing cleaning systems for industry, institutions and the home. Our purpose is to provide well-being to people through hygiene solutions.<br/> <br/> After 34 years, we have become a business group made up of companies specialized in different fields of hygiene, with exports to Central and South America.<br/> <br/> We comply with national and international certifications that guarantee the quality of our products. </p>

       <h1 className=" italic font-semibold text-center text-white text-2xl ">"Hygiene is wellness, it is health"</h1>
     </div>
    </div>
   </section>



<section className="bg-blue-900 pt-12 sm:pt-16 mb-20">
  <div className="max-w-7xl  mx-auto px-4 sm:px-6 lg:px-8 ">
    <div className="max-w-4xl mx-auto text-center ">
      <h2 className="text-5xl font-semibold mt-0 text-center text-white mb-12  ">
      CONSTANT IMPROVEMENT
      </h2>
    </div>
  </div>
  <div className="bg-blue-900 py-px pb-12 sm:pb-16">
    <div className="relative ">
      <div className="absolute inset-0 h-1/2 bg-blue-900"></div>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-4">
            <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-primary-default">
              ISO 9001 Certification
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter ">
                2001
              </dd>
            </div>
            <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-primary-default">
              Member of the International Sanitary Supply Association ISSA
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter">
                2009
              </dd>
            </div>
            <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-primary-default">
              ISO 4500 and ISO 14000 certification
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter">
                2012
              </dd>
            </div>
            <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-primary-default">
              Social Responsibility with the ISO 26000 framework of conduct
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter">
                2016
              </dd>
            </div>
            <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-primary-default">
              Member of the United Nations Global Compact
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter">
                2018
              </dd>
            </div>
            <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-primary-default">
              Good Manufacturing Practices Certification
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter">
                2019
              </dd>
            </div>
            <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-blue-900">
              Member of PECC (Ecuador Zero Carbon Program)
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter">
                2021
              </dd>
            </div>
            <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-blue-900">
              FSC® Certification
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter">
                2023
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="grupo empresarial Unilimpio" className="bg-white flex justify-center mt-12">
  <div className="max-w-7xl mx-10  px-4 sm:px-6 lg:py-24 lg:px-8">
    <div className="max-w-3xl mx-auto text-center flex justify-center">
      <h1 className="text-5xl font-semibold mt-0 text-center text-secondary-lighter  ">OUR PHILOSOPHY</h1>
    </div>
    </div>
</section>


<section className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3  mx-6 mb-16">
  <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div className="flex-1 flex flex-col p-8">
    <StaticImage src="../../../images/nuestro-proposito.jpg"
                    className="flex justify-center mx-6"
                    alt="Misión de Unilimpio"
                    placeholder="blurred"
                    layout="constrained"
        />
      <h3 className="mt-6 text-primary-default  text-3xl font-bold ">OUR PHILOSOPHY</h3><br/>
      <dl className="mt-1 flex-grow flex flex-col justify-between">
        <dt className="sr-only">Title</dt>
        <dd className="text-primary-default text-xl text-left">Well-being of people through hygiene.</dd>
        <dt className="sr-only">Role</dt>
        <dd className="mt-3">

        </dd>
      </dl>
    </div>
  </li>

  <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div className="flex-1 flex flex-col p-8">
    <StaticImage src="../../../images/nuestro-futuro.jpg"
                    className="flex justify-center mx-6"
                    alt="Visión de Unilimpio"
                    placeholder="blurred"
                    layout="constrained"
        />
      <h3 className="mt-6 text-primary-lighter  text-3xl font-bold">Our Future</h3><br/>
      <dl className="mt-1 flex-grow flex flex-col justify-between">
        <dt className="sr-only">Title</dt>
        <dd className="text-primary-default text-xl text-left">To be the first institutional hygiene option recognized for its value offer and its Social Responsibility.</dd>
        <dt className="sr-only">Role</dt>
        <dd className="mt-3">

        </dd>
      </dl>
    </div>

  </li>

  <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div className="flex-1 flex flex-col p-8">
    <StaticImage src="../../../images/nuestros-valores-corporativos.jpg"
                    className="flex justify-center mx-6"
                    alt="Valores corporativos de Unilimpio"
                    placeholder="blurred"
                    layout="constrained"
        />
      <h3 className="mt-6 text-secondary  text-3xl font-bold">Our Corporate Values</h3><br/>
      <dl className="mt-1 flex-grow flex flex-col justify-between">
        <dt className="sr-only">Title</dt>
        <dd className="text-primary-default text-xl text-left"><li>Customer focus</li><li>Continuous improvement and innovation</li> <li>Teamwork</li> <li>Transparency, honesty, courage and faith</li> <li>Sustainable </li> </dd>
        <dt className="sr-only">Role</dt>
        <dd className="mt-3">
        </dd>
      </dl>
    </div>
  </li>
</section>



</Layout>
);

export default AboutUs;
